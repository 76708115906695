import { useEffect } from "react";
import { Button } from "@wbly/ui";
import { trackHeroCta, trackHeroLoad } from "./track-hero-events";

type HeroCtaProps = {
  index: number;
  ctaLabel?: string;
  url?: string;
};

export const HeroCta = (props: HeroCtaProps) => {
  const { index, ctaLabel, url } = props;

  useEffect(() => {
    // Trigger the event when
    // the first slide is loaded
    if (index === 0) {
      trackHeroLoad();
    }
  }, [index]);

  const handleClick = (url: string, index: number) => {
    trackHeroCta(url, index);
    window.location.href = url;
  };

  return (
    <>
      {ctaLabel && url && (
        <Button
          className="cta-button"
          onClick={() => handleClick(url, index)}
          data-eventid={props.index}
        >
          {ctaLabel}
        </Button>
      )}
    </>
  );
};
